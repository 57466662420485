export const colorScheme = {
  ocean: {
    base: 'ocean.10',
    baseAccent: 'ocean.20',
    accent: 'ocean.base',
  },
  forest: {
    base: 'forest.10',
    baseAccent: 'forest.20',
    accent: 'forest.base',
  },
  pebble: {
    base: 'pebble.20',
    baseAccent: 'mountain.20',
    accent: 'mountain.base',
  },
  white: {
    base: 'white',
    baseAccent: 'mountain.20',
    accent: 'mountain.base',
  },
  cyan: {
    base: 'cyan.10',
    baseAccent: 'cyan.20',
    accent: 'cyan.web',
  },
};

export const generalColorScheme = {
  ocean: {
    background: 'ocean.20',
    headline: 'ocean.base',
    accent: 'ocean.base',
    body: 'ocean.base',
  },
  forest: {
    background: 'forest.20',
    headline: 'forest.base',
    accent: 'forest.base',
    body: 'forest.base',
  },
  pebble: {
    background: 'pebble.40',
    headline: 'mountain.base',
    accent: 'mountain.base',
    body: 'mountain.base',
  },
  white_mountain: {
    background: 'white',
    headline: 'mountain.base',
    accent: 'cyan.web',
    body: 'mountain.base',
  },
  mountain: {
    background: 'white',
    headline: 'mountain.base',
    accent: 'cyan.web',
    body: 'mountain.base',
  },
  white: {
    background: 'white',
    headline: 'mountain.base',
    accent: 'cyan.web',
    body: 'mountain.base',
  },
  white_cyan: {
    background: 'white',
    headline: 'cyan.web',
    accent: 'cyan.web',
    body: 'mountain.base',
  },
  cyan: {
    background: 'cyan.10',
    headline: 'mountain.base',
    accent: 'mountain.base',
    body: 'mountain.base',
  },
};

export const getColorScheme = (backgroundColor = 'white', headlineColor?: string) =>
  backgroundColor === 'white' && headlineColor
    ? generalColorScheme[headlineColor as keyof typeof generalColorScheme]
    : generalColorScheme[backgroundColor as keyof typeof generalColorScheme];
