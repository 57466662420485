import { Box, Flex, Text } from '@chakra-ui/react';
import { toTitleCase } from 'helpers/textCase';
import React from 'react';
import { withTranslations } from 'store/translations';

import { IconComp } from './IconComp';

interface TagProps {
  type: TagType;
}
export const tags = [
  'article',
  'event',
  'global_news',
  'other_news',
  'podcast',
  'press_release',
  'video',
  'webinar',
  'white_papers',
  'featured',
  'financial_news',
] as const;
export type TagType = typeof tags[number];

export const Tag = ({ type }: TagProps) => {
  const translations = withTranslations();

  const typeTitled = translations.content_type(type);
  return (
    <Box
      aria-label={
        type === 'featured' ? translations.aria('pageisFeatured') : `${translations.aria('pageIncludes')} ${typeTitled}`
      }
      display='inline-block'
    >
      <Flex
        borderRadius={'15px'}
        p={'2px 10px'}
        alignItems='center'
        background={type === 'featured' ? 'white' : 'mountain.base'}
        color={'white'}
      >
        <IconComp iconName={type} />
        <Text color={type === 'featured' ? 'cyan.web' : 'white'} textStyle='mobile.body.xs'>
          {toTitleCase(typeTitled)}
        </Text>
      </Flex>
    </Box>
  );
};
