import { nodeParser } from '@kontent-ai/delivery-node-parser';
import { createRichTextHtmlResolver, Elements } from '@kontent-ai/delivery-sdk';
import { parseFromString } from 'dom-parser';
import cacheData from 'memory-cache';
// import { LinkType } from '../../lib/enums/LinkType';

export const emptyBody = '<p><br></p>';
const regExpParagraphsAndHeadingsTag = new RegExp(/<(\/)?(p|h[1-6]|ol|ul|table)>/gi);
const regExpHtmlElement = new RegExp(/<(p|h[1-6]|ol|ul|table)>(.|[\r\n]+)*?<\/(p|h[1-6]|ol|ul|table)>/gi);
const regExpRichTextFormatedElement = new RegExp(/<(strong|em|sub|sup)>(.|[\r\n]+)*?<\/(strong|em|sub|sup)>/g);
export const regExpLinkElement = new RegExp(/<a .*?<\/a>/gi);
const regExpListItem = new RegExp(/<(\/)?(li)>/gi);
const regExpListItemSpliter = new RegExp(/<\/li>(.|[\r\n]+)*?<li>/g);
export const listItemsToArray = (html: string) => {
  return html.replace(regExpListItemSpliter, '{splitter}').replace(regExpListItem, '').split('{splitter}');
};

export const resolveHtml = (html: string, textId?: string) => {
  const tagStartIdx = html.indexOf('<') + 1;
  const tagEndIdx = html.indexOf('>');

  const parsedValue: any = [];
  const parsedRowValue: any = [];

  // var parser = new DOMParser();
  const doc = parseFromString(html);
  const bodyobj: any =
    html.substring(tagStartIdx, tagEndIdx) == 'table'
      ? parsedRowValue.map.call(doc.getElementsByTagName('tr'), (tr) => {
          return parsedValue.slice.call(tr.getElementsByTagName('td')).reduce((a, b, i) => {
            return (a['prop' + (i + 1)] = b.textContent.replaceAll('&nbsp;', '')), a;
          }, {});
        })
      : '';

  return {
    tag: html.substring(tagStartIdx, tagEndIdx),
    body:
      (html.substring(tagStartIdx, tagEndIdx) == 'table' &&
        (textId?.includes('ramboll_s_growth_momentum_continues___text_03') ||
          textId?.includes('slow_start_in_a_tough_market___macroeconomic_headw') ||
          textId?.includes('annual_results__test_table'))) ||
      textId?.includes('annual_result_2023___table')
        ? bodyobj
        : html
            .replace(regExpParagraphsAndHeadingsTag, '')
            .replaceAll(
              '<a ',
              `<a style="color: var(--chakra-colors-cyan-ramboll); text-decoration: underline; text-underline-offset: 3px" `,
            )
            .replaceAll('&lt;', '<')
            .replaceAll('&gt;', '>')
            .replaceAll('&amp;', '&')
            .replaceAll('&nbsp;', ' ')
            .replaceAll('<br>', ' ')
            .replaceAll('</br>', ' '),
  };
};
export const htmlResolver = (
  html: Elements.RichTextElement,
  textId?: string,
  category?: string,
  innerLink?: string[],
) => {
  const router = cacheData.get('router');
  //  console.log('router ', router);
  let url: string | undefined = '';
  const collection =
    router !== null && router.isReady && router.query.preview !== undefined && router.query.preview![0];
  const languageVariant =
    router !== null && router.isReady && router.query.preview !== undefined && router.query.preview![1];
  const resolvedRichText = createRichTextHtmlResolver(nodeParser).resolveRichText({
    element: html,
    urlResolver: (linkId, linkText, link) => {
      // const geturl = await setUrl()
      //  console.log('linkText ',linkText)
      // console.log('linkId ',linkId)
      let tempLink;
      link?.urlSlug &&
        innerLink?.map((item) => {
          if (item.includes(link?.urlSlug)) tempLink = item;
        });
      const test = tempLink
        ?.split('/')
        .filter((item) => item !== router.locale)
        .join(' ')
        .trim();
      const internalUrl =
        router.asPath == '/certificerings-ordning-for-brandradgivere' ||
        router.asPath == '/certificerings-ordning-for-statikere'
          ? test
          : tempLink;
      // console.log('internalUrl ', tempLink);
      //  console.log('link?.urlSlug ', link?.urlSlug);

      url =
        category !== 'office' && link?.urlSlug && internalUrl?.includes(link?.urlSlug) && internalUrl != link?.urlSlug
          ? internalUrl
          : router !== null && router.asPath.split('/').includes('editors-only')
          ? '/editors-only/' + collection + '/' + languageVariant + '/' + link?.codename
          : router.query?.subcategory
          ? '/' +
            router.locale +
            '/' +
            router.query?.mainSubpage +
            '/' +
            router.query?.subcategory +
            '/' +
            link?.urlSlug
          : router.query?.mainSubpage && !router.query?.subcategory
          ? '/' + router.locale + '/' + router.query?.mainSubpage + '/' + link?.urlSlug
          : '/' + router.locale + '/' + link?.urlSlug;

      return {
        linkHtml: `<a class="xLink" href="${url}" id="html-resolve-link">${linkText}</a>`,
        // alternatively you may return just url
        linkUrl: 'customUrl',
      };
    },
  }).html;
  if (resolvedRichText.startsWith('<table>')) return [resolveHtml(resolvedRichText, textId)];
  const htmlArray = resolvedRichText.match(regExpHtmlElement);
  return !htmlArray || htmlArray[0].match(emptyBody) ? null : htmlArray.map((htmlString) => resolveHtml(htmlString));
};

export const hasFormating = (body: string) => {
  return regExpRichTextFormatedElement.test(body) || regExpLinkElement.test(body) || body.includes('<br>');
};
