export function toTitleCase(string: string) {
  if (!string || string.length === 0) {
    return '';
  }

  return string.charAt(0).toUpperCase() + string.slice(1);
}

export const toKebabCase = (str: string): string => {
  return str.replace(/([a-z])([A-Z])/g, '$1_$2').toLowerCase();
};

export const formatTags = (tags: string): string => {
  const formattedTags = tags.replace(/^tags/, 'tags__');
  return toKebabCase(formattedTags);
};
