import { hyphenate as hyphenateDk } from 'hyphen/da';
import { hyphenate as hyphenateDe } from 'hyphen/de-1996';
import { hyphenate as hyphenateEn } from 'hyphen/en-gb';
import { hyphenate as hyphenateFi } from 'hyphen/fi';
import { hyphenate as hyphenateNo } from 'hyphen/no';
import { hyphenate as hyphenateSv } from 'hyphen/sv';
import { useState } from 'react';

export const HyphenResult = (content, locale) => {
  const [hyphenContent, setHyphenContent] = useState('');

  switch (locale) {
    case 'en': {
      (async () => {
        await hyphenateEn(content)
          .then((result) => {
            // Plain text - hy[-]phen[-]ate every[-]thing
            setHyphenContent(result);
          })
          .catch((error) => {
            console.error(error);
          });
      })();
      break;
    }
    case 'en-gb': {
      (async () => {
        await hyphenateEn(content)
          .then((result) => {
            // Plain text - hy[-]phen[-]ate every[-]thing
            setHyphenContent(result);
          })
          .catch((error) => {
            console.error(error);
          });
      })();
      break;
    }
    case 'en-us': {
      (async () => {
        await hyphenateEn(content)
          .then((result) => {
            // Plain text - hy[-]phen[-]ate every[-]thing
            setHyphenContent(result);
          })
          .catch((error) => {
            console.error(error);
          });
      })();
      break;
    }
    case 'en-apac': {
      (async () => {
        await hyphenateEn(content)
          .then((result) => {
            // Plain text - hy[-]phen[-]ate every[-]thing
            setHyphenContent(result);
          })
          .catch((error) => {
            console.error(error);
          });
      })();
      break;
    }
    case 'da-dk': {
      (async () => {
        await hyphenateDk(content)
          .then((result) => {
            // Plain text - hy[-]phen[-]ate every[-]thing
            setHyphenContent(result);
          })
          .catch((error) => {
            console.error(error);
          });
      })();
      break;
    }
    case 'de-de': {
      (async () => {
        await hyphenateDe(content)
          .then((result) => {
            // Plain text - hy[-]phen[-]ate every[-]thing
            setHyphenContent(result);
          })
          .catch((error) => {
            console.error(error);
          });
      })();
      break;
    }

    case 'sv-se': {
      (async () => {
        await hyphenateSv(content)
          .then((result) => {
            // Plain text - hy[-]phen[-]ate every[-]thing
            setHyphenContent(result);
          })
          .catch((error) => {
            console.error(error);
          });
      })();
      break;
    }
    case 'fi-fi': {
      (async () => {
        await hyphenateFi(content)
          .then((result) => {
            // Plain text - hy[-]phen[-]ate every[-]thing
            setHyphenContent(result);
          })
          .catch((error) => {
            console.error(error);
          });
      })();
      break;
    }
    case 'no-no': {
      (async () => {
        await hyphenateNo(content)
          .then((result) => {
            // Plain text - hy[-]phen[-]ate every[-]thing
            setHyphenContent(result);
          })
          .catch((error) => {
            console.error(error);
          });
      })();
      break;
    }
  }
  return hyphenContent;
};
